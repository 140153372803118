.wrapper {
  background: #f4eae9;
  width: 100%;
  padding: 40px 0 80px 0;

  .container {
    max-width: 870px;
    margin: 0 auto;
    @media (max-width: 720px) {
      margin: 0 12px;
    }
    .title {
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      margin: 24px 0 8px 0;
    }

    .pubDate {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      color: #999;
      margin: 0 0 32px 0;
    }

    .content {
      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 12px;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }

      iframe {
        width: 100%;
        height: 500px;
        margin-bottom: 20px;
        margin-top: 20px;
        border-radius: 12px;
      }

      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin: 40px 0 20px 0;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin: 40px 0 20px 0;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          margin: 0;
        }
      }
    }
  }
}
