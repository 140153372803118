.wrapperCard {
  margin-bottom: 20px;
  padding: 32px;

  @media (max-width: 720px) {
    padding: 24px 12px;
  }
}

.title {
  color: #333;
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-of-type {
    margin-right: 8px;
  }

  &.disabled {
    color: #999999;
  }

  .titleLabel {
    flex: 1;
  }
}

.formContainer {
  margin-top: 32px;
  display: flex;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
}

.authenticated {
  flex-direction: column-reverse;
  max-width: 320px;
}

.notAuthenticated {
  flex-direction: row;
  max-width: 100%;
  gap: 20px;
  @media (max-width: 720px) {
    flex-wrap: wrap-reverse;
    gap: 0;
  }
}

.input {
  margin-bottom: 16px;
}

.anonymousOrderLabel {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.switchRoot {
  margin-top: 12px;
  margin-bottom: 32px;
}

.socialAuthContainer {
  max-width: 200px;
  margin-bottom: 32px;
  @media (max-width: 720px) {
    margin-left: 0;
    margin-bottom: 24px;
    max-width: initial;
  }
}

.authDesc {
  margin: 0;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.authActions {
  margin: 16px 0;
  display: flex;

  .socialsLink {
    gap: 16px;
  }

  & > *:not(:first-child) {
    margin-left: 16px;
  }
}

.submitBtn {
  @media (max-width: 720px) {
    width: 100%;
  }
}

.completedStepData {
  margin-top: 32px;

  & > * {
    display: flex;
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    & > *:first-of-type {
      width: 45%;
      color: #9a9999;
    }
  }
}

.quickBuyModal {
  width: 100%;
  margin: 0 5px;
}

.formAction {
  margin: 0 0 15px;
}

div.phoneInput {
  margin-bottom: 4px;
}

button.loginBtn {
  background-color: transparent;
  border: 2px solid #5f6eb2;
  border-radius: 6px;
  padding: 10px 24px;
  height: auto;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-weight: 700;

  &:hover {
    border: 2px solid #7983b0;
  }
}

.form {
  flex: 1;
}