.menuList {
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (max-width: 720px) {
    display: flex;
    overflow: auto;
    padding-top: 20px;
  }

  & > li {
    display: flex;
  }

  .menuItem {
    margin-bottom: 21px;

    @media (max-width: 720px) {
      margin-right: 16px;
      padding-bottom: 20px;
      margin-bottom: 0;
      &.feedbackMenuLink {
        min-width: 180px;
      }
    }

    .link {
      display: flex;
      align-items: center;
      color: #333;
      stroke: #691b33;
      stroke-width: 2px;
      gap: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      cursor: pointer;
      padding-left: 16px;

      @media (max-width: 720px) {
        padding-left: 0;
        gap: 10px;
      }
    }

    .logout {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      padding-left: 16px;
    }

    &:not(.active) {
      border-left: 4px solid #fff;
      @media (max-width: 720px) {
        border-left: none;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .hr {
    margin: 20px 0 20px 20px;
    border: 1px solid #ebe4e4;

    @media (max-width: 720px) {
      display: none;
    }
  }
}

.active {
  border-left: 4px solid #691b33;
  height: 25px;


  & .link > * {
    stroke: #000;
  }

  @media (max-width: 720px) {
    border-left: 0;
    border-bottom: 4px solid #691b33;
    width: 150px;
    padding-top: 12px;
  }
}