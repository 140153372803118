.profileContainer {
  padding: 44px;
  border-radius: 12px;
  background: #fff;

  @media (max-width: 720px) {
    padding: 24px 12px;
  }
}

.wrapperBlock {
  margin-top: 24px;
  gap: 44px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 720px) {
    justify-content: center;
    gap: 24px;
  }
  @media (max-width: 300px) {
    flex-wrap: wrap;
    gap: 12px;
  }
  .btnSocial {
    font-size: 13px;
    font-weight: 500;
    gap: 6px;
  }
}

.saveAddresses {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.2px;
  margin: 30px 0;

  @media (max-width: 720px) {
    margin: 24px 0;
  }
}

.separator {
  border: 1px solid #EBE4E4;
  margin: 0;
}
