.wrapper {
  max-width: 870px;
  margin: 0 auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 80px;
  @media (max-width: 720px) {
    margin-bottom: 40px;
  }
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 48%;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.titleDelivery {
  color: #FF5C32;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 10px 0;
}


.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 0;
}

.descriptionDelivery{
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 40px;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}
