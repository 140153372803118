.main {
  background-color: #F4EAE9;
  padding-bottom: 80px;
  @media (max-width: 1045px) {
    padding: 0 12px 60px 12px;
  }
}

.mainText {
  text-align: center;
  margin: 8px 0 30px 0;
  @media (max-width: 720px) {
    margin: 24px 0 18px 0;
  }
}

.wrapper {
  width: 1045px;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;

  @media (max-width: 1045px) {
    width: 100%;
    display: block;
  }
}

.productWrapper {
  padding: 24px;
  margin-bottom: 4px;
}

.breadcrumbs {
  padding: 30px 0 16px;
  @media (max-width: 720px) {
    padding: 20px 0 0;
  }
}

.container {
  padding: 0 0 12px 0;
}

.hrLine {
  height: 1px;
  background-color: #ebe4e4;
  color: #ebe4e4;
  border: none;
  margin: 0;

  &.hrTop {
    margin-top: -1px;
  }

  &.hrBottom {
    margin-bottom: -1px;
  }
}