.header {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.footer {
  padding: 16px 20px;
  @media (max-width: 720px) {
    padding: 16px 12px;
  }
}