.wrapper {
  background-color: #f4eae9;
  padding: 40px 20px 80px;
  // full height minus header and footer height
  min-height: calc(100vh - 136px);

  @media (max-width: 720px) {
    padding: 20px 12px 60px;
  }
}

.layout {
  display: flex;
  flex-wrap: nowrap;
  max-width: 1050px;
  margin: 0 auto;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }

  .content {
    flex: 1;
  }

  .products {
    width: 423px;
    margin-left: 20px;
    height: 100%;

    @media (max-width: 720px) {
      margin: 32px 0 0;
    }
  }
}
