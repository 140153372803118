.wrapper {
  max-width: 870px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
  }
}

.cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 720px) {
    flex-direction: column;
    align-content: center;
  }
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 275px;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.titleDelivery {
  color: #FF5C32;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 10px 0;
}

.dayDelivery {
  margin: 0 0 10px 0;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

.priceWrapper {
  display: flex;

  .priceDelivery {
    margin: 0 0 10px 0;
  }

  .freeDelivery {
    margin: 0 0 10px 0;
  }
}

.descriptionDelivery {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  margin: 0 0 20px 0;

  &:last-of-type {
    margin-bottom: 80px;
    @media (max-width: 720px) {
      margin-bottom: 60px;
    }
  }
}
