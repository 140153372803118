.wrapperTotalSum {
  width: 1045px;
  background-color: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin: 0 auto;
  padding: 24px 16px;
  @media (max-width: 1045px) {
    width: 100%;
  }
  @media (max-width: 720px) {
    padding: 12px;
  }
}

.innerTotalSum {
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    display: block;
    width: 100%;
  }
}

.btnContinueShopping {
  font-size: 14px !important;
  line-height: 100%;
  letter-spacing: 0.42px;
  @media (max-width: 720px) {
    width: max-content;
    margin: 0 auto;
  }
}

.btnTotalSum {
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #5f6eb2;
  background: #5f6eb2;
  color: #fff;
  height: auto;
  padding: 12px 44px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  @media (max-width: 720px) {
    display: flex;
    padding: 12px 0;
    width: 100%;
    justify-content: center;
    margin-bottom: 16px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.totalSumTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 120%;
  color: #333333;
  margin: 0;
  @media (max-width: 720px) {
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.totalSumPrice {
  color: #333;
  font-size: 22px;
  font-weight: 500;
  margin-right: 44px;
  margin-left: 12px;
  @media (max-width: 720px) {
    margin: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
}

.preOrderContainer {
  margin: 24px 0 0 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 4px;

  @media (max-width: 720px) {
    margin: 16px 0 0 0;
  }

  .deliveryShiftDays {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #FF5C32;
    margin: 0;
  }
}