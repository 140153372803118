.errorMessage {
  margin: 0;
  padding-top: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  min-height: calc(100vh - 150px - 280px);

  @media (max-width: 960px) {
    min-height: calc(100vh - 58px - 415px - 62px);
  }
}

.main {
  background-color: #f4eae9;
  padding-bottom: 80px;
  @media (max-width: 720px) {
    padding: 0 12px 60px;
  }
}

.mainText {
  text-align: center;
  padding: 32px 0;
  margin: 0;
  @media (max-width: 720px) {
    padding: 16px 37px 20px;
  }
}

.wrapper {
  max-width: 1045px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #ebe4e4;
  padding: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 720px) {
    display: block;
    padding: 24px 12px 15px;
  }
}

.cardItemTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  margin: 0 0 32px 0;
  @media (max-width: 720px) {
    margin: 24px 0 32px 0;
  }
}

.cardItemTitleOrder {
  margin: 0;
  border-bottom: 1px solid #ebe4e4;
  padding-bottom: 24px;
  margin-bottom: 16px;
  @media (max-width: 720px) {
    margin: 0;
    padding-bottom: 24px;
    margin-bottom: 16px;
  }
}

.productWrapper {
  padding: 0;
  width: 50%;
  @media (max-width: 720px) {
    border-bottom: 1px solid #ebe4e4;
    padding-bottom: 24px;
    width: 100%;
  }
}

.separator {
  border-right: 1px solid #ebe4e4;
  padding: 20px 34px;
  margin: 20px 0;
}

.separatorHr {
  border-right: 1px solid #ebe4e4;
  margin: 0 34px;
}

.detailsWrapper {
  width: 42%;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.totalSumEnd {
  width: 100%;
  display: flex;
}
