.btnBack {
  display: flex;
  margin-bottom: 18px;
  gap: 12px;
  align-items: flex-end;
  @media (max-width: 720px) {
    padding: 0;
    margin-left: 5px;
  }
}

.arrowBack {
  transform: rotate(270deg);
  height: 12px;
  width: 12px;
  margin-top: 6px;
  margin-right: 15px;
  stroke-width: 1px;

  & > svg > path {
    stroke: #691b33;
    stroke-width: 1;
  }
}

.errorMessage {
  margin: 0;
  padding-top: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
