.btnCount {
  border: none;
  background: none;
  padding: 0;
}

.itemCart {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  align-items: center;

  &.disabled {
    user-select: none;

    .columnProductName {
      .wrapperColumnProductName,
      .hidePostcardModalMobile {
        opacity: 0.5;
        pointer-events: none;
      }

      p.article {
        opacity: 1;
      }
    }

    .columnCost,
    .columnCount,
    .priceWithDiscountSecond {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  @media (max-width: 720px) {
    display: block;
  }

  .stockStatus {
    padding: 8px 8px 0 0;
  }

  .columnProductName {
    flex: 5;
    display: flex;

    .imgProduct {
      cursor: pointer;
      width: 80px;
      height: 80px;
      border-radius: 4px;

      @media (max-width: 720px) {
        width: 60px;
        height: 60px;
      }
    }

    .wrapperColumnProductName {
      padding-left: 12px;

      .article {
        color: #a6a6a6;
        font-size: 14px;
        line-height: 100%;
        opacity: 0.5;

        @media (max-width: 950px) {
          margin: 5px 0;
        }
      }
    }
  }

  .columnCost {
    flex: 2;
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 950px) {
      flex: 0;
      padding-right: 100px;
      flex-direction: column;
    }
    @media (max-width: 720px) {
      display: none;
    }

    .priceDiscount {
      color: #a6a6a6;
      font-size: 13px;
      font-weight: 500;
      line-height: 120%;
      text-decoration-line: line-through;
      margin: 0;
    }

    .priceWithDiscount {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      color: #ff5c32;
      margin: 0;
    }
  }

  .containerColumnCount {
    display: flex;
    flex: 4;
    @media (max-width: 720px) {
      display: flex;
      gap: 10px;
      margin-left: 70px;
    }
    @media (max-width: 330px) {
      display: block;
    }

    .columnCount {
      flex: 2;

      &.containerPostcardMessage {
        display: flex;
      }

      @media (max-width: 720px) {
        flex: 0;
      }
    }
  }
}

.deleteBtnMobile,
.deleteBtn {
  cursor: pointer;
  fill: #691b33;
}

.deleteBtn,
.hidePostcardModal {
  @media (max-width: 720px) {
    display: none;
  }
}

.deleteBtnMobile,
.hidePostcardModalMobile {
  display: none;
  @media (max-width: 720px) {
    display: block;
  }
}

.postcardMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  max-height: 40px;
  overflow: hidden;
  white-space: pre-line;
  width: 300px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 960px) {
    width: 260px;
  }
}

.columnSum {
  flex: 2;
  display: flex;
  align-items: center;
  flex-direction: row;

  .priceWithDiscountSecond {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #333333;
    width: 100%;
    margin: 0;
    @media (max-width: 720px) {
      display: none;
    }
  }
}

.actionsPostcard {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
}

.postcardCreationMessage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.withoutPostcardMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #999;
  width: max-content;
}

.addPostcardMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #691b33;
  border-bottom: 1px solid #691b33;
}

.mobileSum {
  @media (min-width: 720px) {
    display: none;
  }
}