.wrapper {
  background-color: #EBE4E4;
  padding: 40px 0 80px 0;
  @media (max-width: 720px) {
    padding: 20px 12px 60px;
  }
  &.content {
    padding: 40px 0 0;
    @media (max-width: 720px) {
      padding: 20px 0 0 0;
    }
  }
}

.title {
  text-align: center;
  margin: 24px 0 0 0;
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 720px) {
    font-size: 24px;
    margin: 24px 0 0 0;
  }
}

.container {
  width: 870px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
  }
}

.desc {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.content {
  margin: 0;

  p:first-of-type {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 40px 0;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin: 0 0 20px 0;
    @media (max-width: 720px) {
      font-size: 20px;
      line-height: 24px;
      word-wrap: break-word;
    }
    margin-top: 40px;
  }

  p:not(p:first-of-type) {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }

  ul {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 12px 0;

    & > li {
      & > p:first-of-type {
        margin: 0;
      }
    }
  }

  p:nth-last-of-type(4) {
    padding: 10px 0 0;
  }
}
