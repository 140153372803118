.wrapper {
  background-color: #EBE4E4;
  padding: 40px 0 80px 0;
  @media (max-width: 720px) {
    padding: 20px 12px 20px;
  }
  &.content {
    padding: 40px 0 0;
    @media (max-width: 720px) {
      padding: 20px 0 0 0;
    }
  }
}

.title {
  text-align: center;
  margin: 24px 0 30px 0;
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 720px) {
    font-size: 24px;
    margin: 24px 0 20px 0;
  }
}

.container {
  width: 870px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
  }
}

.content {
  margin: 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin: 0 0 20px 0;
    @media (max-width: 720px) {
      font-size: 20px;
      line-height: 24px;
    }

    &:last-of-type {
      margin-top: 40px;
    }
  }

  h3:last-of-type {
    margin-top: 40px;
  }

  p:first-of-type {
    margin: 0 0 20px 0;

    & > strong {
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin: 0 0 20px 0;

    & > strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
    }
  }

  p:nth-child(5) {
    margin: 0;
  }

}