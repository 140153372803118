.wrapper {
  background-color: #EBE4E4;
  padding: 40px 0 80px 0;
  @media (max-width: 720px) {
    padding: 20px 12px 60px;
  }
  &.content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.title {
  text-align: center;
  margin: 24px 0 30px 0;
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 720px) {
    font-size: 24px;
    margin: 24px 0 20px 0;
  }
}

.container {
  display: flex;
  justify-content: center;
  gap: 20px;
  @media (max-width: 720px) {
    flex-wrap: wrap;
    gap: 32px;
  }
}