@import "@layout/z-index-layouts.scss";

.mobileDrawer {
  width: 100vw;
  height: calc(100vh + env(safe-area-inset-bottom));
  z-index: $zindex-modal;
}

.searchBlock {
  width: 100%;
  position: relative;
  line-height: 0;
}

.containerDrawer {
  border-radius: 0;
}