.wrapper {
  background-color: #ebe4e4;
  padding: 40px 0 80px 0;
  @media (max-width: 720px) {
    padding: 10px 12px;
  }

  &.content {
    padding: 40px 0 0 0;
    @media (max-width: 720px) {
      padding: 10px 0 0 0;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
  }

  .title {
    text-align: center;
    margin: 24px 0 30px 0;
    color: #333;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @media (max-width: 720px) {
      font-size: 24px;
      margin: 24px 0 20px 0;
    }
  }
}

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  padding: 0 4px;
  min-height: 48px;
  @media (max-width: 720px) {
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > * {
    flex-shrink: 0;
    min-width: 80px;
    margin-right: 10px;
  }
}
