.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.containerImg {
  position: relative;
  max-height: 300px;
  margin: 0 auto 60px;
  max-width: 100%;
  .img {
    border-radius: 12px;
    width: 100%;
  }
}

.container {
  width: 850px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.text {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 60px 0;
  @media (max-width: 720px) {
    width: 100%;
    font-size: 16px;
    margin: 0 0 40px 0;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 80px;
  @media (max-width: 720px) {
    gap: 10px;
    margin-bottom: 60px;
    justify-content: space-between;
  }

  .card {
    background-color: #fff;
    width: 270px;
    border-radius: 12px;
    padding: 20px;
    @media (max-width: 720px) {
      width: 48.5%;
    }
    @media (max-width: 370px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .desc {
      color: #333;
      margin: 10px 0 0 0;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 26.4px */
      @media (max-width: 720px) {
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
}

.containerOffers {
  display: flex;
  gap: 20px;

  @media (max-width: 720px) {
    display: block;
  }
}

.offers {
  display: flex;
  gap: 20px;

  @media (max-width: 720px) {
    display: block;
    margin-bottom: 60px;
  }

  img {
    border-radius: 12px;
    @media (max-width: 720px) {
      width: 350px;
      height: 356px;
    }
  }

  h3 {
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    margin: 0 0 32px 0;
    @media (max-width: 720px) {
      color: #292933;
      margin: 60px 0 20px 0;
      font-size: 20px;
    }
  }

  p {
    color: #333;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 0;
    @media (max-width: 720px) {
      width: 100%;
      font-size: 16px;
      line-height: 140%;
    }
  }
}
