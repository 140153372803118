.wrapper {
  background-color: #F4EAE9;
  padding: 40px 20px 80px;
  @media (max-width: 720px) {
    padding: 0;
  }

}

.layout {
  display: flex;
  flex-wrap: nowrap;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 720px) {
    padding: 0 12px 60px;
  }
  @media (max-width: 720px) {
    width: 100%;
    display: block;
  }

  .pageMenu {
    min-width: 315px;
    padding: 32px 20px 32px 0px;
    border-radius: 12px;
    background: #FFF;
    height: 100%;
    @media (max-width: 720px) {
      width: 100%;
      padding: 2px 20px 10px 0;
      background: none;
    }
  }

  .pageContainer {
    flex: 1;
    margin-left: 40px;
    @media (max-width: 720px) {
      margin-left: 0;
      width: 100%;
    }
  }
}