.wrapperContactsForm {
  width: 420px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 12px;
  @media (max-height: 720px) {
    padding: 20px 20px;
  }

  .title {
    color: #333;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 0;
  }
}

.input {
  margin-bottom: 20px;
}

.message {
  margin-bottom: 24px;
}

.emailRequired {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  margin-top: 0;
}