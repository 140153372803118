.wrapper{
  background-color: #EBE4E4;
  padding: 40px 20px 80px 20px;
  @media (max-width: 720px) {
    padding: 10px 12px;
  }
  &.container {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.breadcrumbs{
  margin: 0 0 30px;
  @media (max-width: 720px) {
    margin: 0 0 15px;
  }
}
