.wrapper {
  background-color: #ebe4e4;
  padding: 40px 20px 80px 20px;
  @media (max-width: 720px) {
    padding: 10px 12px;
  }
  &.container {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.title {
  text-align: center;
  margin: 24px 0 30px 0;
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 720px) {
    font-size: 24px;
    margin: 12px 0 15px 0;
  }
}
