.titleRecently {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 22px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  height: 100%;
}