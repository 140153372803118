.wrapper {
  width: 100%;
  background-color: #ebe4e4;
}

.container {
  width: 1440px;
  padding: 40px 20px 32px 20px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 720px) {
    width: 100%;
    padding: 40px 10px 32px 10px;
  }
}

.blogsContainer{
  margin-bottom: 32px;
}

.wrapperBlog {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1280px) {
    justify-content: center;
  }
  @media (max-width: 720px) {
    gap: 20px;
    justify-content: center;
  }
}

.wrapperInfoBlog {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 0 0 12px 12px;

  &Title {
    margin: 0;
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    display: block;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 44px;
  }

  &DateRelese {
    margin: 0 0 10px 0;
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.mainText {
  color: #333;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 15px 0;

  @media (max-width: 720px) {
    font-size: 24px;
  }
}

.articleCard {
  width: 335px;

  .containerImg {
    background-color: #fff;
    display: block;
    margin: 0;
    border-radius: 12px 12px 0 0;

    .img {
      height: 225px;
      width: 100%;
      border-radius: 12px 12px 0 0;
    }
  }
}

.tabs {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  .tabsContainer {
    justify-content: center;
    margin-bottom: 32px;
    padding: 0 4px;
    @media (max-width: 720px) {
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & > * {
      flex-shrink: 0;
      min-width: 80px;
      margin-right: 10px;
    }
  }
}

.pending {
  color: #999a9a;
  margin: 0;
  font-size: 20px;

  @media (max-width: 720px) {
    font-size: 16px;
  }
}

.pagination {
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
}